(function() {
    'use strict';

    angular.module('uabDataRefresh', [
        'uabDefaultVariable',
        'uabEnvironment'
    ]);
})();
(function() {
    'use strict';

    angular.module('uabDataRefresh').controller(
        'DataRefreshController',
        DataRefreshController
    );

    DataRefreshController.$inject = [
        'DataRefreshService',
        '$scope'
    ];

    function DataRefreshController(
        DataRefreshService,
        $scope
    ) {
        var DataRefreshController = this;

        $scope.$on(
            '$stateChangeStart',
            function() {
                DataRefreshService.notifyStateChange();
            }
        );

        DataRefreshController.clear = clear;
        function clear() {
            DataRefreshController.reset();
            DataRefreshService.reset();
        }

        DataRefreshController.reset = reset;
        function reset() {

        }

        DataRefreshController.init = init;
        function init() {

        }

        DataRefreshController.init();
    }
})();
(function() {
    'use strict';

    angular.module('uabDataRefresh').directive(
        'dataRefresh', dataRefresh
    );

    function dataRefresh() {
        return {
            controller:   'DataRefreshController',
            controllerAs: 'dataRefreshCtrl',
            restrict:     'E'
        };
    }
})();
(function() {
    'use strict';

    angular.module('uabDataRefresh').service(
        'DataRefreshService',
        DataRefreshService
    );

    DataRefreshService.$inject = [
        'DefaultVariableService',
        'EnvironmentService'
    ];

    function DataRefreshService(
        DefaultVariableService,
        EnvironmentService
    ) {
        var DataRefreshService = this;

        DataRefreshService.getSeconds = getSeconds;
        function getSeconds() {
            return new Date().getSeconds();
        }

        DataRefreshService.notifyStateChange = notifyStateChange;
        function notifyStateChange() {
            var seconds = DataRefreshService.getSeconds();

            for (var i = 0; i < DataRefreshService.registry.length; i++) {
                var capsule = DataRefreshService.registry[i];

                if (capsule.updated - seconds > capsule.wait) {
                    capsule.action().then(
                        function() {
                            capsule.updated = DataRefreshService.getSeconds();
                        }
                    );
                }
            }
        }

        DataRefreshService.register = register;
        function register(action, wait) {
            action = DefaultVariableService.getPromise(action);

            action().then(
                function() {
                    var updated = DataRefreshService.getSeconds();

                    wait = DefaultVariableService.get(
                        EnvironmentService.get('dataRefreshWait'),
                        30
                    );

                    DataRefreshService.registry.push(
                        {
                            action:  action,
                            updated: updated,
                            wait:    wait
                        }
                    );

                    return true;
                }
            );

            return false;
        }

        DataRefreshService.reset = reset;
        function reset() {
            DataRefreshService.registry = [];
        }

        DataRefreshService.reset();

        return DataRefreshService;
    }
})();